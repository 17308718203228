.contribute-button {
  margin-left: 18px;
}

.draw-block {
  text-align: center;
}

.draw-form {
  width: "100%";
}

.draw-section {
  font-size: 40px;
  padding-bottom: 40px;
  padding-top: 40px;
}

.result-section {
  text-align: center;
}

.review-section {
  overflow-y: scroll;
}